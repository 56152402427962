import React from 'react';
import { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image';

import "swiper/swiper.min.css";
import "../components/swiper.css";

import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

import { GatsbyImage } from "gatsby-plugin-image"

import SwiperCore, {
  Navigation, Pagination, Thumbs
} from 'swiper/core';
import CarSpecificationsTable from '../components/car-specifications/car-specifications-table';

SwiperCore.use([Navigation, Thumbs, Pagination]);

const StrapiCar = ({ data, location }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { t } = useTranslation();

  const car = data.strapiCar;

  return (
    <>
      <Layout location={location}>
        <Seo
          title={
            car.sellingDate ?
              `${t('Sold')} - ${car.title}` : car.title
          }
          description={car.metaDescription}
        />
        <div>
          <div className="car-overview site-width" style={{
            display: "flex"
          }}>
            <div className="car-overview-details" style={{
              // backgroundColor: '#FFF',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <h1 className="car-page-title">
                {
                  car.sellingDate &&
                  <>{t('Sold')} - </>
                }
                {car.title}
              </h1>
              <span className="car-overview-detail__price" style={{
                fontWeight: '600',
                fontSize: 24,
                display: 'block'
              }}>
                {
                  !car.sellingDate &&
                  <>€ {car.price},-</>
                }
              </span>
              <div className="c-car-card__spec--horizontal">
                <div>
                  <span className="spec-label">{t('Transmission')}</span>
                  <span>{car.transmission?.name}</span>
                </div>
                <div>
                  <span className="spec-label"><Trans>FuelType</Trans></span>
                  <span>{car.fuel_type?.name}</span>
                </div>
                <div>
                  <span className="spec-label"><Trans>Mileage</Trans></span>
                  <span>{car.mileage}</span>
                </div>
                <div>
                  <span className="spec-label"><Trans>YearOfConstruction</Trans></span>
                  <span>{car.firstRegistration}</span>
                </div>
              </div>
              {
                !car.sellingDate &&
                <form>
                  <Link to="/contact" style={{ display: 'block', textAlign: 'center' }} className="btn main-cta main-cta--car-detail">{t('Appointment')}</Link>
                </form>
              }
              {
                !car.sellingDate &&
                <ul className="car-overview-detail__benefits" style={{
                  marginTop: '32px'
                }}>
                  <li className="icon-label car-overview-detail__benefit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    <Trans>CarPass</Trans>
                  </li>
                  <li className="icon-label car-overview-detail__benefit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    <Trans>FullCheckUpAndMaintenance</Trans>
                  </li>
                  <li className="icon-label car-overview-detail__benefit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    <Trans>OneYearWarranty</Trans>
                  </li>
                  {
                    !car.sellingDate &&
                    <li className="icon-label car-overview-detail__benefit">
                      <a target='_blank' href={car.carpassUrl}>
                        <StaticImage
                          width={120}
                          src="../images/logo-carpass.png" alt="Logo Car-pass" />
                      </a>
                    </li>
                  }
                </ul>
              }
              {
                car.sellingDate &&
                <>
                  <p>{t('SoldDescription')}</p>
                  <form>
                    <Link to="/contact" style={{ display: 'block', textAlign: 'center' }} className="btn main-cta main-cta--car-detail">{t('Contact')}</Link>
                  </form>
                </>
              }

            </div>
            <div className="car-overview-gallery">
              <Swiper style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }} spaceBetween={10} navigation={true} thumbs={{ swiper: thumbsSwiper }} className="mySwiper2">
                {car.images.map((i, index) => {
                  return <SwiperSlide>
                    <GatsbyImage
                      image={i.localFile.childImageSharp.gatsbyImageData}
                      width={100}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Logo"
                    />
                  </SwiperSlide>
                })}
              </Swiper>
              <Swiper onSwiper={setThumbsSwiper} spaceBetween={10} slidesPerView={4} freeMode={true} watchSlidesVisibility={true} watchSlidesProgress={true} className="mySwiper">
                {car.images.map((i, index) => {
                  return <SwiperSlide>
                    <GatsbyImage
                      image={i.localFile.childImageSharp.gatsbyImageData}
                      width={100}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Logo"
                    />
                  </SwiperSlide>
                })}
              </Swiper>
            </div>
          </div>
        </div>

        <div className="c-block" style={{ backgroundColor: '#FFF' }}>
          <div className="site-width" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {car.description &&
              <div className="car-detail-description">
                <h2><Trans>AboutThisCar</Trans></h2>
                <ReactMarkdown children={car.description} escapeHtml={false} />
              </div>}
            <CarSpecificationsTable car={car} />
          </div>
        </div>
        <div className="c-block">
          <div className="site-width car-options-container">
            <h2><Trans>CarOptions</Trans></h2>
            <ReactMarkdown children={car.options} escapeHtml={false} />
          </div>
        </div>
      </Layout>

    </>
  )
}

export default StrapiCar

export const query = graphql`
  query CarQuery($slug: String!, $locale: String!) {
    locales: allLocale(filter: {language: {eq: $locale}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    strapiCar(locale: {eq: $locale}, slug: {eq: $slug}) {
      strapiId
      slug
      sellingDate
      title
      metaDescription
      mileage
      price
      carpassUrl
      description
      make {
        name
      }
      transmission {
        name
      }
      fuel_type {
        name
      }
      body {
        name
      }
      manufacturerExteriorColor
      manufacturerInteriorColor
      year
      cc
      powerKw
      powerHp
      rims
      euroStandard
      emission
      yearlyTax
      biv
      tmc
      firstRegistration
      yearlyTaxBrusselsWallonia
      ecoTaxWallonia
      options
      title
      images {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;