import React from 'react'

export const SpecificationsTableRow = ({ label, value, values, separator }) => {
    if ((!value || !value.innerValue) && (!values || !values.filter(v => v.innerValue).count > 0))
        return null;

    return (
        <div>
            <span>{label}</span>
            {
                value && value.innerValue &&
                <div style={{ display: 'flex' }}>
                    {
                        value.prefix &&
                        <span style={{ marginRight: '4px' }}>{value.prefix}</span>
                    }
                    <div>
                        <span>{value.innerValue}</span>
                        {
                            value.unit &&
                            <span>{value.unit}</span>
                        }
                    </div>
                </div>
            }
            {
                values &&
                <>
                    {
                        values.map(v => {
                            <div style={{ display: 'flex' }}>
                                {
                                    value.prefix &&
                                    <span style={{ marginRight: '4px' }}>{value.prefix}</span>
                                }
                                <div>
                                    <span>{v.innerValue}</span>
                                    {
                                        v.unit &&
                                        <span>{v.unit}</span>
                                    }
                                </div>
                            </div>
                        }).join(separator ?? " ")
                    }
                </>
            }
        </div>
    )
}

export default SpecificationsTableRow;