import React from 'react'
import SpecificationsTableRow from './specifications-table-row'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'

const CarSpecificationsTable = ({ car }) => {
    const { t } = useTranslation();

    return (
        <div className="car-specifications-container" style={{ display: 'flex' }}>
            <div className="car-specifications">
                <h2><Trans>Specifications</Trans></h2>
                <div className="properties-table">
                    <SpecificationsTableRow
                        label={t('SpecMileage')}
                        value={{ innerValue: car.mileage, unit: 'km' }}
                    />

                    <SpecificationsTableRow
                        label={t("SpecFirstRegistration")}
                        value={{ innerValue: car.firstRegistration }} />

                    <SpecificationsTableRow
                        label={t('SpecCilinder')}
                        values={[
                            { innerValue: car.cc, unit: 'cc' },
                            { innerValue: car.fuel_type?.name }
                        ]}
                        separator=" "
                    />

                    <SpecificationsTableRow
                        label={t('SpecPower')}
                        values={[
                            { innerValue: car.powerKw, unit: 'kW' },
                            { innerValue: car.powerHp, unit: 'PK' }
                        ]}
                        separator=" / "
                    />

                    <SpecificationsTableRow
                        label={t('SpecColor')}
                        value={{ innerValue: car.manufacturerExteriorColor }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecInterior')}
                        value={{ innerValue: car.manufacturerInteriorColor }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecRims')}
                        value={{ innerValue: car.rims }}
                    />
                </div>
            </div>
            <div className="car-specifications">
                <h2>{t('FiscalSpecifications')}</h2>
                <div className="properties-table">
                    <SpecificationsTableRow
                        label={t('SpecEuroNorm')}
                        value={{ prefix: 'Euro', innerValue: car.euroStandard }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecCo2Emissions')}
                        value={{ innerValue: car.emission }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecYearlyTax')}
                        value={{ prefix: '€', innerValue: car.yearlyTax }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecBIV')}
                        value={{ innerValue: car.biv }}
                    />

                    {/* <SpecificationsTableRow
                        label="BIV (Vlaanderen):"
                        value={{ prefix: '€', innerValue: car.biv }}
                    /> */}

                    <SpecificationsTableRow
                        label={t('SpecTMC')}
                        value={{ prefix: '€', innerValue: car.tmc }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecYearlyTaxWallonia')}
                        value={{ prefix: '€', innerValue: car.yearlyTaxBrusselsWallonia }}
                    />

                    <SpecificationsTableRow
                        label={t('SpecEcoTax')}
                        value={{ prefix: '€', innerValue: car.ecoTaxWallonia }}
                    />
                </div>
            </div>
        </div>
    )
}

export default CarSpecificationsTable
